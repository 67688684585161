import { EventEmitter, Injectable, Directive } from '@angular/core';

@Directive()
@Injectable()
export class LocalAuthRefreshNavBarService {
    static getLoggedInName: EventEmitter<any> = new EventEmitter();

    static login(): void {
        this.getLoggedInName.emit('');
    }

    static logout(): void {
        this.getLoggedInName.emit('');
    }
}

<div  class="aaa">
  <nav class="nav_box">
      <div class="ove"> 
          <div class="fl col-md-4 nopaddings"> 
              <a class="nav_title" href="#">accenture</a> 
          </div> 
          <div class="fl col-md-4 "> 

              <div class="nav_search">Click here to search</div>
          </div> 
          <ul class="fr"> 
              <li class="fl"><a href="#"><span class=""></span> 注册</a></li> 
              <li class="fl"><a href="#"><span class=""></span> 登录</a></li> 
          </ul> 
      </div>     
  </nav>

  <div class="ladingbar">
    <!-- <ng2-slim-loading-bar></ng2-slim-loading-bar> -->
    <slim-loading-bar></slim-loading-bar>
  </div>

</div>
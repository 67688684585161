import { Component , OnInit, OnDestroy }   from '@angular/core';

@Component({
    selector: 'menu-main',
    template: '<router-outlet></router-outlet>',
    styles: [''],
})

export class  MainComponent {

	constructor( ) {}
}

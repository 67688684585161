<div style="background-image: url('./assets/images/grass.jpg'); height: 100vh; width: calc(100% - 400px); background-size: cover;position:relative;float:left;"></div>
<div style="width: 400px; height:100vh;position:relative;float:right;    display: flex;
    align-items: center;
    justify-content: center;">
  <div>
    <div>SELECT ACCOUNT:</div>
    <select [(ngModel)]="account" attr.test="{{account}}" style="width:380px;">
      <option *ngFor="let acc of accounts" [ngValue]="acc.profile">{{acc.profile.unique_name}}</option>
      <option value="Using Temporary Account">Using Your EID</option>
    </select>
    <br/>
    <br/>
    <div [hidden]="account !== 'Using Temporary Account'">
      <label style="width:100px;" for="eid">Enterprise ID:</label><input name="eid" [(ngModel)]="eid" style="width:200px;"
      />
      <br />
      <!-- <label style="width:100px;" for="pk">People Key:</label><input name="pk" [(ngModel)]="pk" style="width:200px;" NumbersOnly
      /> -->
    </div>
    <br/>
    <input (click)="login()" [disabled]="(!account || !account.peoplekey) && !(eid)" attr.test3="{{(!account || !account.peoplekey) && !(eid && pk)}}"
      [(attr.test)]="pk" attr.test1="{{eid}}" class="btn btn-primary" type="button" value="LOGIN" />
  </div>
</div>

<div style="background-color:#eeeeee;text-align: center;">
    <div class="mask" *ngIf="isLoading">
        <mat-spinner class="loading-spinner"></mat-spinner>
    </div>
    <div *ngIf="beforeLoading">
        <div style="background-color:#eeeeee;height: 150px;" *ngIf="!isAccessFormShow"></div>
        <div class="ove cure_box">
            <div class="fl manag"><img class="logo" alt="" src="./assets/images/accenture_logo_final.png"><span style="margin-left: 10px">Manage My Procurement Records (MMPR)</span> </div>
            <ul class="fr icon_box">
                <!-- <li><i class="mdui-icon material-icons cur">search</i></li> -->
                <!-- <li><i class="mdui-icon material-icons">apps</i></li>
            <li><i class="mdui-icon material-icons">notifications</i></li> -->
                <!-- <li><i class="mdui-icon material-icons">help</i></li> -->
                <!-- <li><i class="mdui-icon material-icons">settings</i></li>
            <li><i class="mdui-icon material-icons">person</i></li> -->
            </ul>
        </div>
        <div *ngIf="!isAccessFormShow && !isSuccessShow" class="all_box" style="background-color:#ffffff;margin:0 auto;width: 50%;height: 240px;min-width: 600px">
            <div class="ove another_box" style="padding:0 auto;text-align: center;background-color:#ffffff;">
                <div style="display:inline-block;vertical-align: top;">
                    <i class="icon-desktop_access_disabled" style="font-size: 75px;color: #999999;"></i>
                </div>
                <div style="display:inline-block;text-align: left;margin-left: 40px">
                    <p style="color:rgb(88, 85, 85);font-size: 13px;font-weight: 500;margin-top: 6px;">You currently do not have access to</p>
                    <p style="margin-top:16px;font-size: 14px;font-weight:700">Manage My Procurement Records (MMPR).</p>
                    <!-- <p style="margin-top:20px;color:rgb(88, 85, 85);font-size: 11px;font-weight: 500;">If you need access, please contact the <a href="mailto:{{email}}">Operations team</a>.</p>  -->
                    <!-- <p style="margin-top:20px;color:rgb(88, 85, 85);font-size: 11px;font-weight: 500;">If you need access, please contact the <a href="javascript:void(0);" >Operations team</a>.</p>  -->
                    <p style="margin-top:45px;color:rgb(88, 85, 85);font-size: 13px;font-weight: 500;">
                        <button type="submit" class="btn-shared btn-primary" [ngStyle]="{width: isHaveAccessSubmit ? '200px' : '150px'}" [disabled]="isHaveAccessSubmit || isAccessApproved" (click)="goToAccess()">
              <!-- <i class="mdui-icon material-icons sub_btn">search</i> -->
              <span class="btn_txt">{{isHaveAccessSubmit ? 'Access request pending' : 'Request Access'}}</span>
            </button>
                    </p>
                </div>
            </div>
        </div>

        <div class="user-access-form" *ngIf="isAccessFormShow">
            <div class="headbox">
                <div class="titlBox">
                    <p class="team_title">User Access Request Form</p>
                </div>
                <div class="tab_content">
                    <div class="content_box">
                        <div class="tab_line eid_box">
                            <div class="titl_box fl">
                                <label for="eId" class="fl">
                  <span class="asterisk">*</span>Enterprise ID
                </label>
                            </div>
                            <div class="field_box fr">
                                <div style="position:relative;width: 100%;">
                                    <input id="eId" type="text" [(ngModel)]="formData.eId" class="form-control fl" disabled />
                                </div>
                            </div>
                        </div>
                        <div class="tab_line">
                            <div class="titl_box fl">
                                <label for="country" class="fl">
                  <span class="asterisk">*</span>Country/Location
                </label>
                            </div>
                            <div class="field_box fr">
                                <div style="position:relative;width: 100%;">
                                    <input id="country" type="text" [(ngModel)]="formData.country" class="form-control fl" disabled />
                                </div>
                            </div>
                        </div>
                        <div class="tab_line">
                            <div class="titl_box fl">
                                <label for="businessUnits" class="fl"><span class="asterisk">*</span>Business Unit</label>
                            </div>
                            <div class="field_box fr">
                                <app-single-select [inputId]="'businessUnits'" [isCustLabel]="true" [inputData]="{'filedId': 'businessUnits','value': businessUnit,'isValidation': true, 'emptyString': '','tooltip': businessUnit,'hidePleaseSelect': true, 'IsDisabled': false, 'labelDivStyles': {'height':'0'}}"
                                    [selectionValue]="this.formData.businessUnits" (change)="businessUnit=BUchange($event.value);">
                                </app-single-select>
                            </div>
                        </div>
                        <div class="businessUnitNote">Please note: Service Delivery and Category Management are under P+</div>
                        <div class="tab_line" *ngIf="otherBox">
                            <div class="titl_box fl">
                                <label for="other" class="fl">
                  <span class="asterisk">*</span>
                  Other
                  <i class="mdui-icon material-icons nosapInfo" [tooltip]="otherToolTip">info</i>
                </label>
                            </div>
                            <div class="field_box fr">
                                <textarea id="other" [(ngModel)]="formData.other" (ngModelChange)="txtChange($event)" class="form-control txt_area">
                </textarea>
                            </div>
                        </div>
                        <div class="tab_line">
                            <div class="titl_box fl">
                                <label for="keyCategory" class="fl"><span class="asterisk">*</span>Key Category</label>
                            </div>
                           
                            <div class="field_box fr">
                                <ng-container>
                                    <div dropdown (onShown)="onShown()" container="body" class="block-content" style="width:100%;text-align: left;">
                                        <div class="form-control nowrap justifycontent" style="width:100%" id="buttonWidth" [tooltip]="formData.keyCategory" tooltip-class="tooltip-class-country" dropdownToggle>
                                            <input id="keyCategory" type="text" style="display: none;" [(ngModel)]="formData.keyCategory" />
                                            <div id="dispSelect" class="nowrap slt_box">{{formData.keyCategory}}</div>
                                            <i class="mdui-icon material-icons arr_btn">expand_more</i>
                                        </div>
                                        <multi-select #multiSelect class="mulbox sl_box" [itemSort]="checkSort()" *dropdownMenu [checkedItems]="selectedKeyCategories" [items]="keyCategorys" (itemChange)="selectionChange($event)"></multi-select>
                                    </div>
                                </ng-container>
                            </div>
                            
                        </div>
                        <div class="businessUnitNote" *ngIf="keyCateinfo">Please note: You will only have access to the Key Category selected</div>
                        <div class="tab_line">
                            <div class="titl_box fl">
                                <label for="gu" class="fl"><span class="asterisk">*</span>MU</label>
                            </div>
                            <div class="field_box fr">
                                <input id="gu" type="text" [(ngModel)]="formData.gu" class="form-control" disabled />
                            </div>
                        </div>
                        <div class="tab_line">
                            <div class="titl_box fl">
                                <label for="approver" class="fl"><span class="asterisk">*</span>Approver</label>
                            </div>
                            <div class="field_box fr">
                                <input id="approver" type="text" [(ngModel)]="formData.approver" class="form-control" disabled />
                            </div>
                        </div>
                    </div>

                    <div class="tab_line rele">
                        <button (click)="submit()" type="submit" class="btn_share btn-primary fr ok_btn" [disabled]="isSubmitDisabled || isHaveAccessSubmit">
              <i class="mdui-icon material-icons sub_icon">check</i>
              <span class="btn_txt">{{isHaveAccessSubmit ? 'Access request pending' : 'Request Access'}}</span>
            </button>
                        <button (click)="cancelAdd()" type="submit" class="btn_share btn-secondary fr cancle_btn" [disabled]="isCancelDisabled">
              <i class="mdui-icon material-icons sub_icon">close</i>
              <span class="btn_txt">Cancel</span>
            </button>
                    </div>
                </div>
            </div>
            <div class="bottom_box"></div>
        </div>

        <div class="user-access-form-success" *ngIf="isSuccessShow">
            <div class="headbox">
                <div class="titlBox">
                    <p class="team_title">Request Sent Successfully</p>
                </div>
            </div>
            <div class="tab_content" style="background: #fff;">
                <div class="content_box">
                    <div class="fl">
                        <i class="mdui-icon material-icons check-icon">check_circle</i>
                    </div>
                    <div class="fl" style="margin-left: 30px;line-height: 35px;margin-top: -5px;">
                        <p style="color: #4A8244;text-align: left;">Your request has been submitted successfully.</p>
                        <p>You will be notified via email when your request has been reviewed.</p>
                    </div>
                </div>
            </div>
            <div class="bottom_box"></div>
        </div>
    </div>

    <!-- <div class="ove footer">
  <ul class="term">
      <li style="text-decoration:underline">Terms of use | Privacy Statement</li>


  </ul>
  <div class="fr">
    © 2019 Accenture. All Rights Reserved. Accenture Confidential.
  </div> -->
    <div class="ove footer">
        <div class="term fix_left">
            <ng-container *ngIf="footerInfo && footerInfo.infos">
                <ng-container *ngFor="let info of footerInfo.infos;let i = index;">
                    <span style="margin: 0 2px;" *ngIf="i !== 0">|</span>
                    <div>
                        <a *ngIf="info.content && info.content.length > 0" (click)="showContent(info)"><span>{{info.title}}</span></a>
                        <span *ngIf="!info.content || info.content.length === 0">{{info.title}}</span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="copy-right">
            {{footerInfo ? footerInfo.copyRight : ''}}
        </div>
    </div>

    <ng-template #popup_content>
        <div *ngIf="popupContent">
            <div class="popup-title">
                <div class="popup-title-content">{{popupContent.title}}</div>
                <i (click)="dialogRef.close()" class="popup-close-icon mdui-icon material-icons">clear</i>
            </div>
            <div class="popup-content">
                <div class="row"[ngStyle]="{width: (popupContent.content.length > 1 ? 'calc(95vw - 50px)' : 'calc(45vw - 50px)')}" style="flex-wrap: nowrap;" *ngIf="popupContent.content">
                    <div class="col-auto confidential_header" [ngStyle]="{width: (popupContent.content.length > 1 ? '50%' : '100%')}" *ngFor="let contentItem of popupContent.content">
                        <p>
                            <span style="color: black;"><strong>***</strong></span>
                            <span style="color: #007BFF;"><strong> Highly Confidential.</strong></span>
                            <span style="color: black;"><strong>Accenture Internal Use Only ***</strong></span>
                        </p>
                    </div>
                  </div>
                <div class="row" [ngStyle]="{width: (popupContent.content.length > 1 ? 'calc(95vw - 50px)' : 'calc(45vw - 50px)')}" style="flex-wrap: nowrap;" *ngIf="popupContent.content">
                    <div class="col-auto" [ngStyle]="{width: (popupContent.content.length > 1 ? '50%' : '100%')}" *ngFor="let contentItem of popupContent.content">
                        <p style="padding-left: 10px;" *ngIf="contentItem.title">{{contentItem.title}}</p>
                        <p style="padding: 10px;" *ngFor="let text of contentItem.content" [innerHTML]="text"></p>
                 </div>
                </div>
            </div>
            <div class="popup-footer">
                <button class="confirm" (click)="dialogRef.close()">Close</button>
            </div>
        </div>
    </ng-template>
</div>


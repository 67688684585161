import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfileService } from '../../common/eso/userprofile.service';
import { LocalAuthRefreshNavBarService } from '../../common/eso/local-auth.refresh-navbar';
// import { NzModalService } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EmitService } from '../../common/eso/emit.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public searchText: string;
  public openSelected = false;
  public userName = 'Please Login';
  public picture = './UILibrary/user.png';

  subscription: Subscription;
  constructor(
    private userProfileService: UserProfileService,
    private confirmServ: NzModalService,
    private emitService: EmitService,
    private router: Router,
  ) {
    LocalAuthRefreshNavBarService.getLoggedInName.subscribe(name => this.ngOnInit());
    this.subscription = this.emitService.changeObservable.subscribe(
      res => {
        // this.subscription.unsubscribe();
        console.log('got the eso : ' + res);
        // if (res) {
        //   this.isAccessAdmin = true;
        // }
        this.ngOnInit(true);
      }
    );

  }

  ngOnInit(flag?: any) {

    this.picture = './UILibrary/user.png';
    const enterpriseID = this.userProfileService.EnterpriseId;

    if (flag) {
      if (enterpriseID) {
        this.userName = enterpriseID;
      }
    }

  }

  blobToBase64 = function (blob, cb) {
    const reader = new FileReader();
    reader.onload = function () {
      const dataUrl: any = reader.result;
      const base64 = dataUrl.split(',')[1];
      console.log(base64);
      cb(base64);
    };
    reader.readAsDataURL(blob);
  };


  showPicture(data) {
    // this.picture = 'data:image/jpg;base64,' + data;
  }

  openSelect(event) {
    this.openSelected = true;
  }

  closeSelect(event) {
    if (this.searchText) {
      this.openSelected = true;
    } else {
      this.openSelected = false;
    }
  }

  showHomeUrl(event: any) {
    if (this.router.url !== '/home') {
      this.router.navigate(['/home']);
    }

  }

  changeAccountStatus() {
    if (environment.environment === 'local') {
      return true;
    } else {
      return false;
    }
  }
 
}

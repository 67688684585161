import { Injectable } from '@angular/core';

@Injectable()
export class AnalysisBaseAuthService {

  constructor() { }

  analysis_base_auth(token): any {
    const hash = atob(token);
    console.log('analysis base auth hash is: ' + hash);
    return JSON.parse(hash);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({
    name: 'callback',
    pure: false
})
export class RequestPipe implements PipeTransform {
    transform(items: any[], filterValue: any): any {
        if (!items || !filterValue) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => {
            let flag = true;
            if (filterValue.Air.length > 0 && item.Air && filterValue.Air.indexOf(item.Air.toString()) === -1) {
                let foundAir = false;
                filterValue.Air.forEach(element => {
                    if (element === item.Air) {
                        foundAir = true;
                    }
                });
                flag = foundAir;
            }
            if (flag && filterValue.serviceType.length > 0) {
                let temp = false;
                filterValue.serviceType.forEach(element => {
                    if (item.serviceType && item.serviceType.indexOf(element) > -1) {
                        temp = true;
                    }
                    if (item.Role && !temp) {
                        item.Role.forEach(e => {
                            if (e.roleName === element) {
                                temp = true;
                            }
                        });

                    }
                });
                flag = temp;
            }
            if (flag && filterValue.account.length > 0 && filterValue.account.indexOf(item.subscription) === -1) {
                flag = false;
            }
            return flag;
        });
    }
}

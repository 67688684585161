import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AuthenticationGuard } from './common/eso/authentication.guard';
import { REBAR_AUTH_GUARD } from './core/rebarauth/rebar.auth.module';

import { LocalhostLoginComponent } from './modules/localhost-login/localhost-login.component';
import { NoAccessPermissionComponent } from './modules/NoAccessPermission/NoAccessPermission.component';
import { CheckSaveDialogComponent } from './modules/main/check-save-can-deavtivate/check-save-can-deavtivate';

const routes: Routes = [
  {
    path: '',
    // canActivate: [AuthenticationGuard],
    canActivate: [REBAR_AUTH_GUARD],
    loadChildren: () =>
      import('./modules/main/main/Module').then((m) => m.Module),
  },
  { path: 'login', component: LocalhostLoginComponent },
  { path: 'noaccess', component: NoAccessPermissionComponent },
  // { path: '**',  redirectTo:  '',  pathMatch:  'full' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  // providers: [AuthenticationGuard],
})
export class AppRoutingModule {}

export const Components = [
  LocalhostLoginComponent,
  NoAccessPermissionComponent,
  CheckSaveDialogComponent,
];

import { BrowserModule } from '@angular/platform-browser'
import { DatePipe } from '@angular/common';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { RecentlyAccessedService } from 'app/modules/main/content/ResultsSet/RecentlyAccessed/RecentlyAccessed.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table';
import { MatRadioModule } from '@angular/material/radio';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule, Components } from './app.route';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BaseAuthService } from './common/services/base-auth.service';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { AuthenticationGuard } from './common/eso/authentication.guard';
import { UserProfileService } from './common/eso/userprofile.service';
import { LocalAuthRefreshNavBarService } from './common/eso/local-auth.refresh-navbar';
import './rxjs-operators';
import { AnalysisBaseAuthService } from './common/services/analysis-base-auth.service';
import { EmitService } from './common/eso/emit.service';
import { AjaxService } from './common/services/Ajax/ajax.services';
import { HPEStaticService } from './common/services/staticService/HPEStaticService';
import { ViewRecordStaticService } from './common/services/staticService/ViewRecordStaticService';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { AuthForUserGroupService } from 'app/common/services/UserAuth/AuthForUserGroupService';
import { AuthGuard } from 'app/common/services/UserAuth/AuthGuard';
import { Factory } from 'app/app.factory';
import { MessageService } from 'app/common/services/message/messageService';
import { MyTeamQueueService } from 'app/common/services/MyTeamQueue/MyTeamQueueService';
import { ParentService } from 'app/common/services/NewRecord/parentservice';
import { DirectiveModule } from 'app/common/directive/directive.module';
import { SessionService } from 'app/common/services/session/session.service';
import { RequestPipe } from './common/util/request.pipe';
import { NavbarComponent } from './modules/navbar/navbar.component';
import { MainComponent } from './modules/main/main.component';
import { DatePipe as DatePipeCompatible } from 'app/common/pipe/date.pipe';
import { PageNavigationService } from './common/services/staticService/page-navigation.service';
import { SupplierNameService } from 'app/common/services/NewRecord/supplierNameSearchService';
import { SecondSupplierService } from 'app/common/services/NewRecord/secondsupplierSearchService';
import { TokenInterceptor } from './common/eso/TokenInterceptor';

import { DialogService } from 'app/modules/main/component/dialog/dialog.service';
import { DownloadAlertService } from 'app/modules/main/component/download-alert-popup/download-alert.service';
import { MatConfirmDialogComponent } from 'app/modules/main/component/dialog/mat-confirm-dialog.component';
import { MatConfirmDownloadAlertComponent } from 'app/modules/main/component/download-alert-popup/mat-confirm-dl-alert.component';
import { hostModule } from 'app/modules/main/HostModule/HostModule';
import { ProgressBarService } from './common/progress-bar/progress-bar.service';
import { TooltipModule, TooltipOptions } from 'app/common/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { idleTeamQueueService } from 'app/common/services/MyTeamQueue/IdleTeamQueueService';

import { AppConfigService } from './core/services/app-config.service';
import { MsalRedirectComponent, MsalModule } from '@azure/msal-angular';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

@NgModule({ exports: [],
    declarations: [
        AppComponent,
        RequestPipe,
        NavbarComponent,
        MainComponent,
        MatConfirmDownloadAlertComponent,
        MatConfirmDialogComponent,
        ...Components
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule.forRoot({} as TooltipOptions),
        BsDropdownModule.forRoot(),
        NzUploadModule,
        BsDatepickerModule.forRoot(),
        AppRoutingModule,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatChipsModule,
        MatSortModule,
        MatRadioModule,
        MatCheckboxModule,
        CdkTableModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        DirectiveModule,
        MatDialogModule,
        hostModule,
        RebarAuthModule.forRoot(),
        MsalModule], providers: [
        BaseAuthService,
        AuthenticationGuard,
        UserProfileService,
        // AdalService,
        LocalAuthRefreshNavBarService,
        AnalysisBaseAuthService,
        EmitService,
        AjaxService,
        HPEStaticService,
        ViewRecordStaticService,
        AuthService,
        AuthGuard,
        AuthForUserGroupService,
        Factory,
        MessageService,
        MyTeamQueueService,
        RecentlyAccessedService,
        ParentService,
        DialogService,
        DownloadAlertService,
        SupplierNameService,
        SecondSupplierService,
        idleTeamQueueService,
        SessionService,
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: (config: SessionService) => () => config.load(),
        //   deps: [SessionService],
        //   multi: true,
        // },
        DatePipeCompatible,
        {
            provide: DatePipe,
            useExisting: DatePipeCompatible
        },
        PageNavigationService,
        ProgressBarService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService) => () => config.load(),
            deps: [AppConfigService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EmitService {

    constructor() { }
    private changeSubject = new BehaviorSubject<any>(0);
    changeObservable = this.changeSubject.asObservable();

    changeNav(value: any) {
        this.changeSubject.next(value);
    }
}

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Factory } from '../../app.factory';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { RecordType, RecordStatus } from 'app/common/enum/type.enum';
import { UserProfileService } from 'app/common/eso/userprofile.service';
import { ReturnMessage } from 'app/common/enum/type.enum';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-noAccessPermission',
  templateUrl: './noAccessPermission.component.html',
  styleUrls: ['./noAccessPermission.component.css']
})
export class NoAccessPermissionComponent implements OnInit {
  email;

  public popupContent;
  public dialogRef: MatDialogRef<TemplateRef<any>>;
  public footerInfo;
  public service: any = this.factory.getService();
  public isAccessFormShow = false;
  public userinfo: any;
  public selectedKeyCategories = [];
  public keyCategorys = [];
  public businessUnit = '';
  public keyCategoryForSub = '';
  public otherToolTip = 'What business entity are you from? Why do you need access?';
  public otherBox = false;
  public keyCateinfo = false;
  public isSuccessShow = false;
  public isLoading = false;
  public isCancelDisabled = false;
  public beforeLoading = false;
  public BUToApproverArr = [
    'P+ Service Delivery',
    'P+ Category Management',
    'P+ Assisted Buy',
    'P+ Contractor Exchange',
    'P+ Contract Services',
    'P+ Procurement Operations',
    'P+ Sourcing Support'
  ];
  public formData: any = {
    eId: '',
    userName: '',
    country: '',
    businessUnits: '',
    other: '',
    keyCategory: '',
    gu: '',
    approver: '',
  };
  public isSubmitDisabled = true;
  public isHaveAccessSubmit = false;
  public isAccessApproved: boolean = false;

  @ViewChild("popup_content")
  private popupContentRef: TemplateRef<any>;

  constructor(
    public activeRoute: ActivatedRoute,
    private httpService: HttpClient,
    public dialog: MatDialog,
    private factory: Factory,
    private auth: AuthService,
    private router: Router,
    private UserProfile: UserProfileService) { }

  ngOnInit() {
    this.beforeLoading = false;
    //this.email="1@1.com";
    this.activeRoute.queryParams.subscribe(params => {
      this.email = params.email;
    });
    console.log('app-noAccessPermission on init');
    this.httpService.get('assets/footer-info.json')
      .subscribe(data => {
        this.footerInfo = data;
      });
    this.isApproved();
  }


  isApproved() {
    let searchQ  = "format=json&q=members:[type:Group] and type:Person and surname:\"" + this.UserProfile.EnterpriseId + "\"";
    let searchProperties = 'properties=TotalResults';
    this.service.HPServiceLocation(searchQ, searchProperties).then(res => {
      if (res.TotalResults > 0) {
        //this.isAccessApproved = true;
        this.router.navigate(['/home']);
      } else {
        this.getIsHaveAccessSubmit();
      }
    }, err => {
      console.log(err);
    });
  }


  getIsHaveAccessSubmit(callBackFunc?: (...args) => void) {
    var searchQ  = "format=json&q=(EnterpriseID:\"" + this.UserProfile.EnterpriseId + "\" and (UserAccessStatus:\"" + RecordStatus.PendingGUReview +"\" or UserAccessStatus:\"" + RecordStatus.PendingAdminReview +"\")) and type:[\"User Access Request\"]";
    var searchProperties = "properties=TotalResults";
    this.beforeLoading = true;
    this.isLoading = true;
    this.service.RecordListCount(searchQ,searchProperties).then(res => {
      this.isLoading = false;
      if(res.TotalResults > 0) {
        this.isHaveAccessSubmit = true;
      } else if(callBackFunc){
        callBackFunc();
      }
    }, err => {
      this.isLoading = false;
      console.log(err);
    });
  }

  public showContent(content) {
    this.popupContent = content;
    this.dialogRef = this.dialog.open(this.popupContentRef, {
      disableClose: false,
      autoFocus: false,
      maxWidth: '95vw',
    });
  }

  goToAccess() {
    this.getIsHaveAccessSubmit(this.goToAccessAfter.bind(this));
  }

  goToAccessAfter(): void {
    let eid = this.UserProfile.EnterpriseId;
    this.isLoading = true;
    this.isSubmitDisabled = true;
    let _promise = Promise.all([
      this.service.UserAccessGetKeyCategory(),
      this.service.UserAccessGetBU(),
    ]);
    _promise.then(res => {
      if (res) {
        this.service.getUserByEid({ 'Eid': eid }).then(response => {
          if (response.Status === ReturnMessage.Success) {
            const user = {
              EID: response.Data.EID,
              LogInAs: response.Data.LogInAs,
              Uri: response.Data.Uri,
              Email: response.Data.Email,
              Group: response.Data.Group,
              KeyCategory: response.Data.KeyCategory,
              Gu: response.Data.Gu,
              OPEmail: response.Data.OPEmail,
              Salutation: response.Data.Salutation,
              Country: response.Data.Country,
              GuLead: response.Data.GuLead
            };
            this.userinfo = user;
            this.formData = {
              eId: this.userinfo.EID,
              userName: this.userinfo.Salutation,
              country: this.userinfo.Country,
              businessUnits: '',
              other: '',
              keyCategory: '',
              gu: this.userinfo.Gu,
              approver: this.userinfo.GuLead,
            };
            let kc;
            [kc, this.formData.businessUnits] = [...res];
            this.keyCategorys = kc.map(item => item.Key);
            this.isAccessFormShow = true;
            this.isLoading = false;
          }
        }, err => {
          console.log('get user error', err);
          this.isLoading = false;
        });
      }
    });
  }

  getNotEmpty(): void {
    let disableBol;
    if (this.businessUnit
      && this.keyCategoryForSub
      && this.formData.approver
      && this.formData.gu
      && this.formData.country
      && this.formData.eId) {
      disableBol = false;
      if (this.otherBox && this.formData.other === '') {
        disableBol = true;
      } else {
        disableBol = false;
      }
    } else {
      disableBol = true;
    }

    this.isSubmitDisabled = disableBol;
  }

  submit(): void {
    this.getIsHaveAccessSubmit(this.submitAfter.bind(this));
  }

  submitAfter(): void {
    this.isLoading = true;
    this.isSubmitDisabled = true;
    this.isCancelDisabled = true;
    const data = {
      RecordType: RecordType.UserAccessRequest,
      RecordUri: '',
      RecordFields: [
        { 'Key': 'Enterprise ID', 'Value': this.userinfo.EID, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'LocationUri', 'Value': this.userinfo.Uri, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Requestor Name', 'Value': this.formData.userName, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Request Approver', 'Value': this.formData.approver, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Key Category For User Access', 'Value': this.keyCategoryForSub, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Business Unit', 'Value': this.businessUnit, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Other Reason', 'Value': this.formData.other, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Primary GU', 'Value': this.formData.gu, 'IsCustomer': true, 'IsBool': false },
        { 'Key': 'Primary Country', 'Value': this.formData.country, 'IsCustomer': true, 'IsBool': false },
      ],
      User: this.userinfo,
    };
    this.service.UserAccessSubmit(data)
      .then((res) => {
        this.isLoading = false;
        if (res.Status.toLocaleLowerCase() == 'success') {
          this.isSuccessShow = true;
          this.isAccessFormShow = false;
          this.isSubmitDisabled = false;
          this.isCancelDisabled = false;
        }
      }, err => {
        this.isLoading = false;
        this.isSubmitDisabled = false;
        this.isCancelDisabled = false;
      });
  }

  cancelAdd(): void {
    this.clear();
    this.isAccessFormShow = false;
    this.isSubmitDisabled = false;
    this.isCancelDisabled = false;
  }

  onShown() {
    $('.sl_box ul.filter-dropdown-menu').css('width', $('div.block-content').css('width'));
  }

  selectionChange(event: any) {
    this.selectedKeyCategories = event.filter(m => m.checked).map(m => m.name);
    this.formData.keyCategory = this.selectedKeyCategories.join(',');
    this.keyCategoryForSub = this.selectedKeyCategories.join(';');
    this.getNotEmpty();
  }

  checkSort() {
    return 'ASC';
  }

  BUchange(eventValue: any) {
    
    this.businessUnit = eventValue;
    this.formData.approver = this.userinfo.GuLead;
    this.formData.gu = this.userinfo.Gu;
    this.keyCateinfo = false;
    if (this.BUToApproverArr.indexOf(this.businessUnit) < 0 && this.businessUnit) {
      this.formData.approver = 'Admin';
    }
    if (this.businessUnit === 'Other') {
      this.formData.gu = 'Global';
      this.otherBox = true;
      this.keyCateinfo = true;
    } else if ('Other' !== this.businessUnit && this.formData.gu == 'Global') {
      this.otherBox = false;
      this.formData.other = '';
    } else if( this.businessUnit === 'Audit' || this.businessUnit === 'CIO' || this.businessUnit === 'Legal' || this.businessUnit === 'Deal Services' || this.businessUnit === 'V&A' || this.businessUnit === 'ESM'){
      this.keyCateinfo = true;
      this.otherBox = false;
    } else {
      this.otherBox = false;
      this.formData.other = '';
    }
    this.getNotEmpty();
    return eventValue;
    
  }

  txtChange(value: any) {
    this.formData.other = value;
    this.getNotEmpty();
  }

  clear() {
    this.formData = {
      eId: '',
      userName: '',
      country: '',
      businessUnits: '',
      other: '',
      keyCategory: '',
      gu: '',
      approver: '',
    };
    this.selectedKeyCategories = [];
    this.keyCategorys = [];
    this.businessUnit = '';
    this.otherBox = false;
    this.keyCateinfo = false;
  }
}

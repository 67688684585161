export const localhostAccounts = [
    {
        profile: {
            peoplekey: 10001,
            unique_name: 'T20238mmrpDSid001'
        },
        username: 'T20238mmrpDSid001@accenture.com'
    },
    {
        profile: {
            peoplekey: 10002,
            unique_name: 'T20238mmrpDSid002'
        },
        username: 'T20238mmrpDSid002@accenture.com'
    },
    {
        profile: {
            peoplekey: 10003,
            unique_name: 'T20238mmrpDSid003'
        },
        username: 'T20238mmrpDSid003@accenture.com'
    } 
];

import { Injectable } from '@angular/core';

@Injectable()
export class BaseAuthService {

  constructor() { }

  make_base_auth(user, password): any {
    const tok = user + ':' + password;
    const hash = btoa(tok);
    return 'Basic ' + hash;
  }

}

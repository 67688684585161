import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
import { localhostAccounts } from "environments/localhost.accounts";

@Component({
  selector: "app-localhost-login",
  templateUrl: "./localhost-login.component.html",
  styleUrls: ["./localhost-login.component.css"],
})
export class LocalhostLoginComponent implements OnInit {
  // accounts = environment.localAccounts;
  accounts = localhostAccounts;
  account;
  eid;
  pk;
  constructor(private router: Router, private rebarAuthService: RebarAuthService) {}

  ngOnInit() {}

  login() {
    let loginuser = this.account;
    this.pk = "100006";
    if (this.eid && this.pk) {
      loginuser = {
        unique_name: this.eid,
        peoplekey: this.pk,
      };
    }
    console.log("LocalhostLoginComponent loginuser:"+loginuser);
    this.router.navigate(["/home"], {
      fragment: "localuser=" + JSON.stringify(loginuser),
    });
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
// import { AdalService } from './adal.service';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
import { UserProfileService } from './userprofile.service';

@Injectable()
export class AuthenticationGuard  {
  constructor(
    private router: Router,
    // private adalService: AdalService,
    private rebarAuthService: RebarAuthService,
    private activatedRoute: ActivatedRoute,
    private userProfile: UserProfileService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const value: boolean = this.adalService.checkAuthentication(route);
    const value: boolean = this.rebarAuthService.isUserAuthenticated();
    if (!value) {
      return false;
    }
    let myroute = state.root;
    return new Promise<boolean>((resolve, reject) => {
      // eslint-disable-next-line no-debugger
      // debugger
      console.log('AuthenticationGuard-canActivate:');
      console.log('this.userProfile:', this.userProfile);
      console.log('myroute:', myroute);
      while (myroute.children.length > 0) {
        myroute = myroute.children[0];
      }
      if (this.userProfile.EnterpriseId) {
        resolve(true);
      } else {
        resolve(false);
        // this.router.navigate(['/401']);
      }
    });
  }
}
